
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

// Mixins

@Component({
  components: {
    RouterLink,
  },
})
export default class TableDataAtom extends Vue {
  @Prop() content: any;
  @Prop() showBoldPrice: boolean;
  @Prop() showIcon: boolean;
  @Prop({ default: false }) isLastItem: boolean;

  checkDataType() {
    if (typeof this.content === "object" && this.content !== null)
      return "object";
    return "simple";
  }

  isObject(content: any): boolean {
    return content && typeof content === "object";
  }

  isZeroContent(content) {
    return content.startsWith("0");
  }

  getSvgIcon(content) {
    if (this.isZeroContent(content)) {
      return false;
    }

    return typeof content === "number" ? content <= 0 : !content.includes("-");
  }

  get iconRotationStyle(): string {
    return this.isZeroContent(this.content) ? "transform: rotate(-90deg);" : "";
  }
}

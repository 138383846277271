
import { Component, Emit, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class Input extends Vue {
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "Label" }) label: string;
  @Prop() id: string;
  @Prop() type: string;
  @Prop() name: string;
  @Prop() min: number;
  @Prop() max: number;
  @Prop() maxlength: number;
  @Prop({ default: "Input" }) placeholder: string;
  @Prop({ default: "on" }) autocomplete: string;
  @Prop() value: any;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: "" }) supportMessage: string;
  @Prop() assetsIconFile: string;
  @Prop({ default: "valid" }) validationState: string;

  get _validationState() {
    if (this.supportMessage != "") {
      return "invalid";
    } else {
      return "valid";
    }
  }

  @Emit()
  emitInputValue(value) {
    if (typeof this.type === "string") {
      return value.trim();
    }
    return value;
  }

  @Emit()
  emitFocus(value) {
    return value;
  }
}
